import React from 'react'
import { view as Box } from '../auth/changePassword/'
import Logo from '../common/logo'
import Footer from '../common/footer'

const ChangePassword = (props) => {
  return (
    <div className="box">
        <Box {...props} />
      <Footer />
    </div>
  )
}

export default ChangePassword
