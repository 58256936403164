import React from 'react'

const config = (props) => {
    let redirectURL = '';
    let source = props.source;
    if(source){
        if(source === 'prctoken') {
            redirectURL = 'http://' + source + '.com/?'
        } else if(source === 'value') {
            redirectURL = 'https://' + source + '.prctoken.com/login?'
        } else if(source === 'kuiconnect') {
            redirectURL = 'http://kuiconnect.webrtc.win/login?' + 'ip%3D' + document.referrer + "%26"
        }else if(source.indexOf(".") != -1) {
            redirectURL = 'https://' + source + '/login?'
        } else {
            redirectURL = 'https://' + source + '.webrtc.win/login?'
        }
    }

  return {
  weibo: {
    api: 'https://api.weibo.com/oauth2/authorize',
    param: {
      client_id: '1034093780',
      redirect_uri: encodeURIComponent('https://api.webrtc.win/v1/vdn/login/weibo'),
      response_type: 'code',
      state: redirectURL
    },
    img_uri: 'http://www.sinaimg.cn/blog/developer/wiki/24x24.png'
  },
  alipay: {
    api: 'https://openauth.alipay.com/oauth2/publicAppAuthorize.htm',
    param: {
      app_id: '2017103109640418',
      redirect_uri: encodeURIComponent('https://api.webrtc.win/v1/vdn/login/alipay'),
      scope: 'auth_user',
      state: redirectURL
    },
    img_uri: 'https://i.alipayobjects.com/common/favicon/favicon.ico'
  },
  wechat: {
    api: 'https://open.weixin.qq.com/connect/qrconnect',
    param: {
      appid: 'wx14ce75ec0c7c8b77',
      response_type: 'code',
      scope: 'snsapi_login',
      redirect_uri: encodeURIComponent('https://api.webrtc.win/v1/vdn/login/wechat'),
      state: redirectURL
    },
    img_uri: 'https://open.weixin.qq.com/zh_CN/htmledition/res/assets/res-design-download/icon24_appwx_logo.png'
  },
  qq: {
    api: 'https://graph.qq.com/oauth2.0/authorize',
    param: {
      client_id: '101420789',
      response_type: 'code',
      redirect_uri: encodeURIComponent('https://api.webrtc.win/v1/vdn/login/qq'),
      state: redirectURL
    },
    img_uri: 'http://qzonestyle.gtimg.cn/qzone/vas/opensns/res/img/bt_blue_24X24.png'
  }
}};

const style = {
  float: 'right',
  marginLeft: 10
}

const ThirdEnter = (props) => (
  <div className="thirdEnter">
    { Object.entries(config(props)).map((k, index) => {
      let link = k[1].api + '?' + Object.entries(k[1].param).reduce((q, x) => `${q}&${x[0]}=${x[1]}`, '').slice(1)
      return (
        <a key={index} href={link} style={style}><img src={k[1].img_uri} alt={k[0]} height={24}/></a>
      )
    }) }
  </div>
)

export default ThirdEnter
