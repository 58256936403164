import React from 'react'
import Card from '@material-ui/core/Card'
import { view as LoginBox } from '../auth/login/'
import Logo from '../common/logo'
import Footer from '../common/footer'

const Login = (props) => {
  return (
    <div className="box">
        <LoginBox {...props} />
        {/*<Row gutter={24}>
          <Col xs={{span: 24}} sm={{span: 12}}>
            <LoginBox {...props} />
          </Col>
          <Col xs={{span: 0}} sm={{span: 12}}>
            <Logo />
          </Col>
        </Row>*/}
      <Footer />
    </div>
  )
}

export default Login
