import React from 'react'
import moment from 'moment'
import Typography from '@material-ui/core/Typography';

class Footer extends React.Component {
  getWord = () => {
    const param = window.location.search
    if(param.indexOf('shadowfog') > 0) {
      return 'ShadowFog'
    } else if(param.indexOf('iccrouter') > 0) {
      return 'ICCRouter'
    } else if(param.indexOf('openfogos') > 0) {
      return 'OpenFogOS'
    } else {
      return "Pear"
    }
  }
  render() {
    const word = this.getWord()
    return (

          <Typography variant="body2" color="textSecondary" align="center" style={{marginTop: '10px'}}>
              Powered by {word} {moment().year()}
          </Typography>

    )
  }
}

export default Footer
