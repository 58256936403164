import moment from 'moment'
const Status = {
  LOADING: 'loading',
  SUCCESS: 'success',
  FAILURE: 'failure'
}

const checkTime = () => (
    ((moment().unix() - localStorage.getItem('account_timestamp')) < 6 * 3600 ) ? true : false
)

export default (state = {result: {}, realnameInfo: {}, isAuthenticated: false}, action) => {
    const setStorage = (result) => {
        localStorage.setItem('account_token', result.token)
        localStorage.setItem('account_userid', result.user_id)
        localStorage.setItem('account_username', result.username)
        localStorage.setItem('account_timestamp', moment().unix())
    }
  switch(action.type) {
    case 'AUTH/LOGIN_STARTED': {
      return {...state, loginStatus: Status.LOADING}
    }
    case 'AUTH/LOGIN_SUCCESS': {
      localStorage.setItem("token", action.result.token);
      return {...state, loginStatus: Status.SUCCESS, result: action.result, isAuthenticated: true}
    }
    case 'AUTH/LOGIN_FAILURE': {
      return {...state, loginStatus: Status.FAILURE}
    }
    case 'AUTH/SIGNUP_STARTED': {
      return {...state, signupStatus: Status.LOADING}
    }
    case 'AUTH/SIGNUP_SUCCESS': {
      return {...state, signupStatus: Status.SUCCESS, result: action.result}
    }
    case 'AUTH/SIGNUP_FAILURE': {
      return {...state, signupStatus: Status.FAILURE}
    }
    case 'AUTH/REALNAMEINFO_STARTED': {
      return {...state, realnameInfoStatus: Status.LOADING}
    }
    case 'AUTH/REALNAMEINFO_SUCCESS': {
      return {...state, realnameInfoStatus: Status.SUCCESS, realnameInfo: action.result}
    }
    case 'AUTH/REALNAMEINFO_FAILURE': {
      return {...state, realnameInfoStatus: Status.FAILURE}
    }
    case 'AUTH/REALNAME_STARTED': {
      return {...state, realnameStatus: Status.LOADING}
    }
    case 'AUTH/REALNAME_SUCCESS': {
      return {...state, realnameStatus: Status.SUCCESS}
    }
    case 'AUTH/REALNAME_FAILURE': {
      return {...state, realnameStatus: Status.FAILURE}
    }
    case 'FORGET/SUBMIT_STARTED': {
      return {...state, forgetPwdStatus: Status.LOADING}
    }
    case 'FORGET/SUBMIT_SUCCESS': {
      return {...state, forgetPwdStatus: Status.SUCCESS}
    }
    case 'FORGET/SUBMIT_FAILURE': {
      return {...state, forgetPwdStatus: Status.FAILURE}
    }
      case 'AUTH/MAGIC_STARTED': {
          return {...state, magicStatus: Status.LOADING}
      }
      case 'AUTH/MAGIC_SUCCESS': {
          return {...state, magicStatus: Status.SUCCESS, magic: action.result}
      }
      case 'AUTH/MAGIC_FAILURE': {
          return {...state, magicStatus: Status.FAILURE}
      }
      case 'AUTH/LOGIN': {
          let query = encodeURIComponent(Object.entries(action.data).reduce((q, x) => `${q}&${x[0]}=${x[1]}`, '').slice(1));
          localStorage.setItem('redirectQuery', '/login?' + query);
          return {...state, isAuthenticated: true}
      }
    default: {
      return state
    }
  }
}
