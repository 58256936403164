import React from 'react'
import { connect } from 'react-redux'
import ERROR from '../../Error'
import { login, loginThird, getMagicBoxURL, loginQuery } from '../actions'
import LoginForm from './loginForm'
import Button from '@material-ui/core/Button'
import {messageOk, messageError} from "../../utils/actions";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import blue from "@material-ui/core/colors/blue";
import {withStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';

const styles = theme => ({
    main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
            width: 600,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    card: {
        marginTop: theme.spacing.unit * 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    avatar: {
        margin: theme.spacing.unit,
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing.unit,
    },
    button: {
        margin: theme.spacing.unit * 2,
    },
    aLink: {
        color: blue[500],
    },
});

class LoginBox extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.onFormChange = this.onFormChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this);
    this.openWaitDialog = this.openWaitDialog.bind(this);
    this.closeWaitDialog =  this.closeWaitDialog.bind(this);
    this.state = {
      formData: {},
      queryData: {},
        waitDialogOpen: false,
        title: "登录"
    }
  }
  onFormChange(event) {
    this.setState({

      formData: {
        ...this.state.formData,
        [event.target.name]: event.target.value
      }
    })
  }
  openWaitDialog = ()=> {
      this.setState({
          waitDialogOpen: true
      })
  };
    closeWaitDialog = ()=> {
        this.setState({
            waitDialogOpen: false
        })
    };
  onSubmit() {
    this.props.onSubmit(this.state.formData, this.state.queryData)
  }
  getArgs() {
    let args = {},
        query = this.props.location.search,
        search = decodeURIComponent(query.substring(1)),
        reg = /(?:([^&]+)=([^&]+))/g,
        match = reg.exec(search)
    while (match) {
      args[match[1]] = match[2]
      match = reg.exec(search)
    }
    return args
  }

  redirect = (url) => () => {
      window.location.href = url + localStorage.getItem('redirectQuery');
  }
  render () {
    const {classes} = this.props;
    const formParam = {
      onSubmit: this.onSubmit,
      onChange: fields => this.onFormChange(fields),
      loading: this.props.loading,
      query: this.props.location.search,
        messageError: this.props.messageError,
        title: this.state.title,
      ...this.state.formData,
      ...this.state.queryData
    }
      const waitDialog = <Dialog open={this.state.waitDialogOpen} onClose={this.closeWaitDialog}>
          <DialogTitle>内测中</DialogTitle>
          <DialogContent>
              该功能正在内测中，如有兴趣，请联系我们
          </DialogContent>
          <DialogActions>
              <Button onClick={this.closeWaitDialog} color="primary">
                  取消
              </Button>
              <Button onClick={this.closeWaitDialog} color="primary" autoFocus>
                  确定
              </Button>
          </DialogActions>
      </Dialog>;
    console.log("magic", this.props.magic);
    return <div>
        {!this.props.isAuthenticated? <LoginForm {...formParam}/>:
            <main className={classes.main}>
                <Card className={classes.card}>
                    <CardContent>
                        <Button onClick={this.redirect("https://i.openfogos.com")} color="primary" variant="contained" style={{marginRight:10}}>OpenFogOS</Button>
                        {this.props.magic? <a href={"http://" + this.props.magic[0].Domain} target='_blank'> <Button  color="primary" variant="contained" style={{marginRight:10}}>KM</Button></a>:
                            <Button  color="primary" variant="contained" style={{marginRight:10}} onClick={this.openWaitDialog}>KM</Button>}

                    </CardContent>

                </Card>
                {waitDialog}
            </main>
            }
    </div>


  }
  componentDidMount() {
    let args = this.getArgs();
    this.setState({
      formData: {
        username: args.username
      },
      queryData: {
        isBinding: (args.state && args.openid && args.logintype) ? true : false,
        // source: (args.state === 'public') ? 'nms' : (args.state || args.source || 'nms'),
        source: (args.state === 'public') ? '' : (args.state || args.source || ''),
        openid: args.openid,
        logintype: args.logintype,
      },
        title: args.title || "登录",
    })
      if (args.token && args.user_id && args.username) {
          this.props.onRequire({
              token: args.token,
              user_id: args.user_id,
              username: args.username
          })
      }
  }
  componentDidUpdate() {
      if(!this.state.queryData.source){
          this.props.getMagic();
      }
  }
}


const mapStateToProps = (state) => {
  return {
    loading: (state.auth.loginStatus === 'loading') ? true : false,
      // logged: (state.auth.loginStatus === 'success') ? true : false,
      isAuthenticated: state.auth.isAuthenticated,
      magic: state.auth.magic
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: (data, args) => {
      /*let temp = {}
      for (let k in data) {
        temp[k] = data[k].value
      }
      temp.openid = args.openid
      temp.logintype = args.logintype*/
        console.log('args.source-------------------------------------------------------', args.source);
        console.log('args.source.indexOf("http")---------------------------------------', args.source.indexOf("http"));
      var param = {
        data: {
            ...data,
            openid: args.openid,
            logintype: args.logintype
        },
        success: (result) => {
            localStorage.setItem('token', result.token);
          let query = encodeURIComponent(Object.entries(result).reduce((q, x) => `${q}&${x[0]}=${x[1]}`, '').slice(1));
            localStorage.setItem('redirectQuery', '/login?' + query);
            if(!args.source){
                dispatch(getMagicBoxURL({
                    /*error: (error) => {
                        dispatch(messageError(ERROR[error.errorcode]))
                    }*/
                }));
                dispatch(messageOk('登录成功，请选择要登入的系统'));
              return;
            }
          let redirectURL = ''
            if(args.source.indexOf("http") != -1){
                redirectURL = args.source;
                window.location.href = redirectURL + '/login?' + query;
                return
            }else if(args.source === 'prctoken') {
            redirectURL = 'http://' + args.source + '.com'
          } else if(args.source === 'value') {
            redirectURL = 'https://' + args.source + '.prctoken.com/login'
          } else if(args.source === 'kuiconnect') {
              redirectURL = 'http://kuiconnect.webrtc.win/login'
          }else if(args.source.indexOf(".") != -1) {
            redirectURL = 'https://' + args.source + '/login'
          } else {
            redirectURL = 'https://' + args.source + '.webrtc.win/login'
          }
            if(args.source === 'kuiconnect') {
                query = 'ip%3D' + document.referrer + "%26" + query;
            }
          window.location.href = redirectURL + '?' + query
        },
        error: (error) => {
            dispatch(messageError(ERROR[error.errorcode]))
        }
      }
      if (args.isBinding) {
        dispatch(loginThird(param))
      } else {
        if(args.source === 'value') {
          if(param.data.username === 'nmsadmin' || param.data.username === 'blockchain') {
            dispatch(login(param))
          } else {
              this.props.messageError('对不起，你没有权限进入！')
          }
        } else {
          dispatch(login(param))          
        }
      }
    },
      getMagic: () => {
          dispatch(getMagicBoxURL({
              /*error: (error) => {
                  dispatch(messageError(ERROR[error.errorcode]))
              }*/
          }));
      },
      messageOk: (data) => {
        dispatch(messageOk(data));
      },
      messageError: (data) => {
          dispatch(messageError(data));
      },
      onRequire: (data) => {
          dispatch(loginQuery(data))
      }
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(LoginBox))
