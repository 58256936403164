import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import Login from './pages/Login'
import Signup from './pages/Signup'
// import Realname from './pages/Realname'
import ForgetPwd from './pages/ForgetPwd'
import ChangePassword from './pages/ChangePassword'

const Routes = () => (
  <BrowserRouter>
    <Switch>
      {/*<Route path="/bind" component={Login}></Route>*/}
      {/*<Route path="/realname" component={Realname}></Route>*/}
      <Route path="/signup" component={Signup}></Route>
      <Route path="/login" component={Login}></Route>
      <Route path="/forget" component={ForgetPwd}></Route>
      <Route path="/update/password" component={ChangePassword}></Route>
      <Route path="/" component={Login} exact></Route>
      <Route path="*" component={Login}></Route>
    </Switch>
  </BrowserRouter>
)

export default Routes
