import {createStore, combineReducers, applyMiddleware, compose} from 'redux'
import thunkMiddleware from 'redux-thunk'
import { reducer as Auth } from './auth/'
import { reducer as Utils } from './utils/'

const reducer = combineReducers({
  auth: Auth,
    utils: Utils,
})

const middlewares = [thunkMiddleware]

const storeEnhancers = compose(
  applyMiddleware(...middlewares),
  (window && window.devToolsExtension) ? window.devToolsExtension() : (f) => f,
)

export default createStore(reducer, {}, storeEnhancers)
