import xhr from '../Xhr'

const API = (id) => ({
  auth: {
    login: {
      url: process.env.REACT_APP_API_SERVER + '/v1/vdn/login',
      method: 'POST'
    },
    signup: {
      url: process.env.REACT_APP_API_SERVER + '/v1/vdn/signup',
      method: 'POST'
    },
    realnameInfo: {
      url: process.env.REACT_APP_API_SERVER + `/v1/vdn/${id}/real_name_auth`,
      method: 'GET'
    },
      magicBoxURL: {
          url: process.env.REACT_APP_API_MAGICBOX_SERVER + `/v1/magicbox/info`,
          method: 'GET'
      },
    realname: {
      url: process.env.REACT_APP_API_SERVER + `/v1/vdn/${id}/real_name_auth`,
      method: 'POST'
    },
    loginThird: {
      url: process.env.REACT_APP_API_SERVER + '/v1/vdn/thirdlogin/bind',
      method: 'POST'
    },
    forgetPwd: {
      url: process.env.REACT_APP_API_SERVER + `/v1/vdn/app/forget_password`,
      method: 'POST'
    },
      changePwd: {
          url: process.env.REACT_APP_API_SERVER + `/v1/vdn/1/password`,
          method: 'POST'
      },
  }
})

const codeAPI = (data) => ({
  sendCode: {
    url: process.env.REACT_APP_API_SERVER + `/v1/vdn/vcode?mobile=${data.phone}&country_code=${data.code}`,
    method: 'GET'
  },
  forgrtSendCode: {
    url: process.env.REACT_APP_API_SERVER + `/v1/vdn/username/vcode?username=${data.username}`,
    method: 'GET'
  }
})

export const login = ({data, ...event}) => {
  return xhr({
    api: API().auth.login,
    action: 'AUTH/LOGIN',
    data,
    event
  })
}

export const sendCode = ({data, ...event}) => {
  return xhr({
    api: codeAPI(data).sendCode,
    action: 'SEND/CODE',
    event
  })
}

export const forgetSendCode = ({data, ...event}) => {
  return xhr({
    api: codeAPI(data).forgrtSendCode,
    action: 'FORGET/SEND',
    event
  })
}

export const forgetPwdSubmit = ({data, ...event}) => {
  return xhr({
    api: API().auth.forgetPwd,
    action: 'FORGET/SUBMIT',
    data,
    event
  })
};

export const changePwdSubmit = ({data, ...event}) => {
    return xhr({
        api: API().auth.changePwd,
        action: 'PASSWORD/CHANGE',
        data,
        event
    })
}

export const signup = ({data, ...event}) => {
  console.log('data :', data);
  return xhr({
    api: API().auth.signup,
    action: 'AUTH/SIGNUP',
    data,
    event
  })
}


export const submitRealname = ({data, ...event}) => {
  return xhr({
    api: API(data.userid).auth.realname,
    action: 'AUTH/REALNAME',
    token: data.token,
    data,
    event
  })
}

export const loginThird = ({data, ...event}) => {
  return xhr({
    api: API().auth.loginThird,
    action: 'AUTH/LOGIN',
    data,
    event
  })
}

export const getMagicBoxURL = ({...event}) => {
    return xhr({
        api: API().auth.magicBoxURL,
        action: 'AUTH/MAGIC',
        event
    })
}

export const loginQuery = (data) => ({
    type: 'AUTH/LOGIN',
    data
})
